import { message, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  FlexibleSearchRequest,
  FlexibleSearchResponse,
  getInvestigationSearch,
  INV_SEARCH_VIEW,
} from '@signifyd/http'
import { createFlexibleSearchRequest, SearchKeys } from '@signifyd/utils'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import useGetUserData from 'core/hooks/useGetUserData'
import useSimilarityParams from 'core/hooks/useSimilarityParams'
import { useIsThirdGen } from '@signifyd/components'
import { useGlobalFilterJoinType } from '../queries/useGlobalFilterJoinType'
import { useTablePagination } from '../queries/useTablePagination'
import { useTableQueryParams } from '../queries/useTableQueryParams'
import { useTableSorting } from '../queries/useTableSorting'
import { createEnhancedInvestigations } from '../containers/SearchResultsTable/SearchResultsTable.utils'

export type SearchResultsResponse =
  FlexibleSearchResponse<INV_SEARCH_VIEW.INVESTIGATION> & {
    flexibleSearchQuery: FlexibleSearchRequest<INV_SEARCH_VIEW.INVESTIGATION>
  }

const MAX_SEARCH_RESULTS = 10000

const useGetSearchResults = (): UseQueryResult<SearchResultsResponse> => {
  const { t } = useTranslation()
  const { query } = useTableQueryParams()
  const similarity = useSimilarityParams()
  const { isAdmin, isTraining } = useGetUserData()

  const isThirdGen = useIsThirdGen()

  const { normalizedPurchaseCreatedAt, ...rest } = query

  const {
    query: { current, pageSize },
  } = useTablePagination()

  const {
    query: { sortOrder, orderBy },
  } = useTableSorting()

  const { joinType } = useGlobalFilterJoinType()

  const notificationKey = isAdmin ? 'notificationAdmin' : 'notification'

  // As the user sorts & filters, it will update the query string which will cascade to this query and update search results
  return useQuery(
    [
      'searchResults',
      normalizedPurchaseCreatedAt,
      rest,
      current,
      pageSize,
      sortOrder,
      orderBy,
      joinType,
      similarity,
    ],
    async () => {
      const flexibleSearchQuery = createFlexibleSearchRequest({
        view: INV_SEARCH_VIEW.INVESTIGATION,
        filters: query,
        // We know these exist because they are checked in the enabled flag
        pagination: {
          currentPage: current!,
          size: pageSize!,
        },
        sort: {
          by: orderBy as SearchKeys,
          order: sortOrder!,
        },
        joinType: joinType!,
      })

      const { data: search } = await getInvestigationSearch(flexibleSearchQuery)

      if (!isThirdGen && search.totalResults >= MAX_SEARCH_RESULTS) {
        notification.open({
          key: notificationKey,
          message: t(
            `investigationSearch.limitedResults.${notificationKey}.message`
          ),
          description: t(
            `investigationSearch.limitedResults.${notificationKey}.description`
          ),
          duration: 0,
          style: {
            top: 80,
          },
        })
      } else {
        notification.destroy(notificationKey)
      }

      return {
        ...search,
        flexibleSearchQuery,
        investigations: createEnhancedInvestigations(
          search.investigations,
          isTraining
        ),
      }
    },
    {
      enabled:
        !!normalizedPurchaseCreatedAt &&
        !!current &&
        !!pageSize &&
        !!orderBy &&
        !!sortOrder &&
        !!joinType &&
        !similarity,
      onError: (error) => {
        console.error(error)

        if (typeof error === 'string') {
          message.error(error)
        }
      },
      keepPreviousData: true,
    }
  )
}

export default useGetSearchResults
