import useGetUserData from 'core/hooks/useGetUserData'
import useGetInvestigationSearchContext from 'core/hooks/useGetInvestigationSearchContext'
import { useTeamCustomerIdQueryParams } from 'pages/InvestigationSearchPage/queries/useTeamCustomerIdQueryParams'
import getColumns, { SearchTableColumnProps } from './ColumnConfig'

const useGetColumns = (): {
  columns: Array<SearchTableColumnProps>
  isFetching: boolean
} => {
  const params = useTeamCustomerIdQueryParams()
  const userData = useGetUserData()
  const { data: userColumns, isFetching } =
    useGetInvestigationSearchContext(params)

  if (!userData || !userColumns || isFetching) {
    return {
      columns: [],
      isFetching,
    }
  }

  const { isTraining, isAdmin, currentUser } = userData

  const columns = getColumns({
    isTraining,
    isCustomer: !isAdmin,
    isRulesBuilderUser: !!currentUser?.features.RULES_BUILDER,
  })

  const availableTableColumns: Array<SearchTableColumnProps> = []

  userColumns.availableFields.forEach((availableField) => {
    const userAvailableColumn = columns.find(
      (column) => column.contextMatches === availableField
    )

    if (userAvailableColumn) {
      availableTableColumns.push(userAvailableColumn)
    }
  })

  return {
    columns: availableTableColumns,
    isFetching,
  }
}

export default useGetColumns
