import { FC } from 'react'
import { NavigationBar } from '@signifyd/components'
import { UserData } from 'core/hooks/useGetUserData'
import {
  filterMenuItemByAuth,
  getNavigationItems,
} from '@signifyd/components/src/thirdGenComponents/NavigationBar/navigation.utils'
import useUpdateUiState from '../NavigationWrapper/useUpdateUiState'

interface Props {
  userData: UserData
}

const ThirdGenNavBar: FC<Props> = ({ userData }) => {
  const { currentUser, userConfigs } = userData
  const updateUiState = useUpdateUiState(currentUser)

  const userRolesSet = new Set(currentUser.roles)

  const items = filterMenuItemByAuth(
    getNavigationItems(),
    userRolesSet,
    currentUser
  )

  return (
    <NavigationBar
      user={currentUser}
      userConfigs={userConfigs}
      items={items}
      onLanguageSelectionChange={async (selectedLocale) => {
        await updateUiState.mutateAsync({ locale: selectedLocale })
      }}
    />
  )
}

export default ThirdGenNavBar
