import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EyeInvisibleOutlined } from '@ant-design/icons'
import { Text } from '@signifyd/components'
import { Flex } from 'antd'
import styles from './HiddenColumnsEmpty.less'

const HiddenColumnsEmpty: FC = () => {
  const { t } = useTranslation()

  return (
    <Flex align="center" vertical className={styles.hiddenEmpty}>
      <EyeInvisibleOutlined className={styles.emptyIcon} />
      <Text className={styles.header} size="lg">
        {t('columnDrawer.empty.header')}
      </Text>
      <Text className={styles.text} size="md">
        {t('columnDrawer.empty.value')}
      </Text>
    </Flex>
  )
}

export default HiddenColumnsEmpty
