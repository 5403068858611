import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Popconfirm } from 'antd'
import { T4, Text } from '@signifyd/components'
import styles from './ColumnDrawer.less'

interface Props {
  onClear: () => void
  onApply: () => void
  resetToDefault: () => void
  onCancel: () => void
  hasChanges: boolean
  loading: boolean
}

const ColumnDrawerFooter: FC<Props> = ({
  loading,
  hasChanges,
  onClear,
  onApply,
  resetToDefault,
  onCancel,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'columnDrawer' })

  return (
    <Flex flex="row" justify="space-between">
      <Popconfirm
        overlayClassName="sig-popconfirm"
        icon={null}
        title={
          <div data-test-id="drawerResetConfirm">
            <T4>{t('resetToDefault.title')}</T4>
            <Text className={styles.popConfirmReset} type="secondary">
              {t('resetToDefault.subtext')}
            </Text>
          </div>
        }
        onConfirm={resetToDefault}
        okText={t('resetToDefault.reset')}
        cancelText={t('resetToDefault.keep')}
      >
        <Button data-test-id="resetButton" type="link" disabled={loading}>
          {t('reset')}
        </Button>
      </Popconfirm>

      <Flex flex="row" gap="small">
        <Popconfirm
          overlayClassName="sig-popconfirm"
          icon={null}
          disabled={!hasChanges}
          title={
            <div data-test-id="drawerCloseConfirm">
              <T4>{t('confirmChanges.title')}</T4>
              <Text type="secondary">{t('confirmChanges.subtext')}</Text>
            </div>
          }
          onConfirm={onClear}
          okText={t('confirmChanges.cancel')}
          cancelText={t('confirmChanges.keep')}
        >
          <Button
            disabled={loading}
            data-test-id="cancelChangesButton"
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
        </Popconfirm>

        <Button
          onClick={onApply}
          disabled={!hasChanges || loading}
          data-test-id="okApplyButton"
          type="primary"
        >
          {t('apply')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default ColumnDrawerFooter
