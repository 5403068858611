import { useDroppable } from '@dnd-kit/core'
import { FC } from 'react'

const HIGH_Z_INDEX = 9999

interface Props {
  id: string
}

const Droppable: FC<Props> = ({ id, children }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  })

  const style = {
    zIndex: isOver ? 0 : HIGH_Z_INDEX,
  }

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  )
}

export default Droppable
