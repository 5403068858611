export default {
  button: 'Columns',
  hiddenColumnsButton: 'Hidden columns ({{count}})',
  title: 'Columns',
  reset: 'Reset to default',
  cancel: 'Cancel',
  apply: 'Apply',
  visibleColumns: 'Visible columns',
  hiddenColumns: 'Hidden columns',
  resetToDefault: {
    keep: 'Keep',
    reset: 'Reset',
    title: 'Reset columns to default',
    subtext:
      'This will restore columns to the default view. All changes will be lost.',
  },
  confirmChanges: {
    keep: 'Keep',
    cancel: 'Cancel changes',
    title: 'Cancel changes to columns?',
    subtext: 'Any changes will be lost.',
  },
  defaultColumns: {
    caseId: 'Case ID',
    date: 'Date',
    guaranteeDisposition: 'Guarantee disposition',
  },
  empty: {
    header: 'Hidden columns will appear here',
    value:
      'Use the icon beside each column name above to hide columns from the table',
  },
  showColumn: 'Show',
  hideColumn: 'Hide',
  savedColumns: {
    error: 'Error retrieving saved column preferences. Please try again',
  },
  hiddenColumnsPopconfirm: {
    title: 'Data columns not shown',
    subtext:
      'Some data columns are hidden. Use column customization to adjust which columns are visible.',
  },
  seeColumns: 'See columns',
  skip: 'Skip',
}
