import { ReactNode, FC } from 'react'
import {
  FieldMatch,
  INV_SIMILARITY_FIELD,
  InvestigationInfo,
  SEARCH_FIELD,
} from '@signifyd/http'
import { ExternalLink } from '@signifyd/components'
import cx from 'classnames'
import styles from './SearchResultsTable.less'

const INV_SEARCH_PREFIX = '/casesearch?'
const SCORE_THRESHOLDS = {
  high: 500,
  low: 299,
}
type ScoreThresholdClass = 'high' | 'medium' | 'low'

const isLinkedField = (
  similarityField: INV_SIMILARITY_FIELD,
  linkedFields?: Array<FieldMatch> | null
): boolean => {
  if (!linkedFields) {
    return false
  }

  return linkedFields.some((field) =>
    field?.fieldName?.includes(similarityField)
  )
}

interface LinkedProps {
  linkedFields?: Array<FieldMatch> | null
  similarityField: INV_SIMILARITY_FIELD
}

export const LinkedFieldWrapper: FC<LinkedProps> = ({
  linkedFields,
  similarityField,
  children,
}) => {
  const isLinked = isLinkedField(similarityField, linkedFields)

  if (isLinked) {
    return (
      <div
        data-test-id={`linkedField-${similarityField}`}
        className={cx([styles.fullBgWrapper, styles.primaryFieldMatch])}
      >
        {children}
      </div>
    )
  }

  return <div data-test-id={`field-${similarityField}`}>{children}</div>
}

export const renderSearchLink = (
  text: string | null | undefined,
  searchKey: SEARCH_FIELD,
  overrideDisplayText?: string
): ReactNode => {
  if (!text) {
    return null
  }

  return (
    <ExternalLink
      url={`${INV_SEARCH_PREFIX}${searchKey}=${text}`}
      data-test-id={`searchLink${searchKey}`}
      className={styles.externalLinks}
      applyBaseColor={false}
    >
      {overrideDisplayText ?? text}
    </ExternalLink>
  )
}

export const getScoreClass = (score: number): ScoreThresholdClass => {
  if (score > SCORE_THRESHOLDS.high) {
    return 'high'
  }

  return score > SCORE_THRESHOLDS.low ? 'medium' : 'low'
}

const doAddressesMatch = ({
  billingAddress,
  recipients,
}: InvestigationInfo): boolean => {
  const billingAddr = billingAddress.fullAddress
  const shippingAddr = recipients?.[0]?.address?.fullAddress

  if (billingAddr && shippingAddr) {
    return shippingAddr === billingAddr
  }

  return false
}

export const renderAddress = (
  searchKey: SEARCH_FIELD,
  rowData: InvestigationInfo,
  columnName: INV_SIMILARITY_FIELD,
  address?: string
): ReactNode => {
  if (!address) {
    return null
  }

  const { investigationId } = rowData
  const applyLinkedClass = isLinkedField(columnName, rowData?.fieldMatches)
  const applyMatchedClass = doAddressesMatch(rowData) && !applyLinkedClass

  const label = (
    <ExternalLink
      applyBaseColor={false}
      className={cx({
        [styles.addressLinkMatch]: applyMatchedClass,
        [styles.externalLinks]: applyLinkedClass,
      })}
      url={`${INV_SEARCH_PREFIX}${searchKey}=${encodeURIComponent(address)}`}
      data-test-id={`searchLink${searchKey}${investigationId}`}
    >
      {address}
    </ExternalLink>
  )

  return (
    <div
      className={cx([styles.fullBgWrapper, styles.address], {
        [styles.addressMatch]: applyMatchedClass,
      })}
    >
      {label}
    </div>
  )
}

export const COLUMN_CONTEXT_MAP = {
  investigationId: 'caseId',
  createdAt: 'date',
  guaranteeDisposition: 'guaranteeDisposition',
  chargeback: 'chargeback',
  investigationReviewDisposition: 'orderReviewFlag',
  cardHolderName: 'cardHolderName',
  recipientFullName: 'deliveryRecipient',
  userAccountEmail: 'userAccountEmail',
  recipientConfirmationEmail: 'confirmationEmail',
  billingAddress: 'billingAddress',
  recipientAddress: 'deliveryAddress',
  userAccountPhone: 'userAccountPhone',
  ipGeolocationFull: 'ipGeolocation',
  recipientConfirmationPhone: 'confirmationPhone',
  teamName: 'team',
  customerId: 'customer',
  items: 'products',
  discountCode: 'discountCode',
  avsResponseCode: 'avs',
  cvvResponseCode: 'cvv',
  cardBin: 'bin',
  cardBinCountryCode: 'binCountryCode',
  cardBinCountry: 'binCountry',
  cardLastFourDigits: 'cardLastFourDigits',
  bindbBank: 'bindbBank',
  browserIp: 'ipAddress',
  deviceId: 'deviceId',
  shippingMethod: 'shippingMethod',
  orderTotalAmount: 'orderTotal',
  sellerId: 'sellerId',
  sellerParentEntity: 'parentEntity',
  sellerName: 'sellerName',
  signifydScore: 'score',
  orderChannel: 'channel',
  userAccountNumber: 'userAccountNumber',
  orderId: 'orderId',
  transactionId: 'transactionId',
  isTestInvestigation: 'isTest',
  paymentGateway: 'paymentGateway',
  paymentMethod: 'paymentMethod',
  authorizationGatewayStatus: 'authorizationStatus',
  authorizationFailureReason: 'authorizationFailureReason',
  guaranteeAutoDecisionDescription: 'guaranteeAutoDecisionReasonDescription',
  guaranteeAutoDecisionReason: 'guaranteeAutoDecisionReason',
  guaranteeReviewTimeoutDisposition: 'guaranteeReviewTimeoutDisposition',
  recommendedDecisionDisposition: 'recommendedDecisionDisposition',
  billingAddressProvinceCode: 'billingAddressProvinceCode',
  teamId: 'teamId',
  checkoutPolicyName: 'checkoutPolicyName',
  checkoutPolicyAction: 'checkoutPolicyAction',
  returnPolicyAction: 'returnPolicyAction',
  returnPolicyName: 'returnPolicyName',
  accountHolderTaxId: 'accountHolderTaxId',
  processingPolicy: 'processingPolicy',
  recordLocator: 'recordLocator',
  leadPassengerName: 'leadPassenger',
} as const

export type ColumnContextMapKeys = keyof typeof COLUMN_CONTEXT_MAP
