import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Typography, Flex } from 'antd'
import { useIsThirdGen } from '@signifyd/components'
import { UserData } from 'core/hooks/useGetUserData'
import { CustomerSelect, TeamSelect } from '../TeamAndCustomerSelects'
import styles from './SearchTitleBar.less'

const { Title } = Typography

interface Props {
  userData: UserData
}

const SearchTitleBar: FC<Props> = ({ userData }: Props) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  if (isThirdGen) {
    return (
      <Row align="middle" justify="space-between">
        <Col>
          <Title level={1} data-test-id="searchTitle">
            {t('investigationSearch.search')}
          </Title>
        </Col>
        <Col className={styles.container}>
          <Flex gap="large">
            {userData.isAdmin && <CustomerSelect />}
            <TeamSelect />
          </Flex>
        </Col>
      </Row>
    )
  }

  return (
    <Row
      align="middle"
      justify="space-between"
      className={styles.searchContent}
    >
      <Col>
        <span className={styles.searchTitle} data-test-id="searchTitle">
          {t('investigationSearch.search')}
        </span>
      </Col>
      <Col className={styles.container}>
        {userData.isAdmin && <CustomerSelect />}
        <TeamSelect />
      </Col>
    </Row>
  )
}

export default SearchTitleBar
