import { FC, useEffect, memo } from 'react'
import { debounce } from 'lodash'

interface Props {
  onResize: () => void
  debounce?: number
}

const ResizeListener: FC<Props> = memo(
  ({ onResize, debounce: debounceInterval = 200 }) => {
    const handleResize = debounce(onResize, debounceInterval)

    useEffect(() => {
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    return null
  }
)

export default ResizeListener
