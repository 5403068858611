import { FC } from 'react'
import { Flex } from 'antd'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'
import styles from './DynamicItems.less'

interface Props {
  id: string
}

const Draggable: FC<Props> = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Flex
      align="center"
      justify="space-between"
      key={id}
      className={styles.itemContainer}
      gap={8}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {children}
    </Flex>
  )
}

export default Draggable
