import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { queryKeys } from '@signifyd/components'
import { replaceUserUIState, UiState, User } from '@signifyd/http'

const useUpdateUiState = (
  user: User | undefined
): UseMutationResult<void, unknown, UiState> => {
  const queryClient = useQueryClient()

  return useMutation(
    async (uiStateToUpdate: UiState) => {
      const initialUiState = user?.uiState ?? {}

      await replaceUserUIState({
        ...initialUiState,
        ...uiStateToUpdate,
      })
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.currentUser],
        })
      },
    }
  )
}

export default useUpdateUiState
