import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { LockFilled } from '@ant-design/icons'
import { Flex } from 'antd'
import { DefaultColumns } from 'Constants'
import styles from './LockedItems.less'
import { ColumnProps } from '../../ColumnDrawer'

interface Props {
  lockedColumns: Array<ColumnProps>
}

const LockedItems: FC<Props> = ({ lockedColumns }) => {
  const { t } = useTranslation()

  return (
    <Flex gap={8} vertical>
      {lockedColumns.map((column) => (
        <Flex
          align="center"
          gap={8}
          className={styles.itemContainer}
          key={column.id}
        >
          <LockFilled
            style={{ fontSize: 20 }}
            data-test-id={`columnLocked-${column.id}`}
            className={styles.padlock}
          />
          <Text className={styles.lockedItem}>
            {t(`columnDrawer.defaultColumns.${column.name as DefaultColumns}`)}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}

export default LockedItems
