import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Icon, { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Text, DragIcon } from '@signifyd/components'
import { Button, Flex, Tooltip, Skeleton } from 'antd'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import investigationSearchColumns from 'locales/en-US/investigationSearch'
import styles from './DynamicItems.less'
import { ColumnProps } from '../../ColumnDrawer'
import Droppable from './Droppable'
import Draggable from './Draggable'

const TRUNCATE_LENGTH = 35

enum ORDER {
  UP = 'up',
  DOWN = 'down',
}

type ColumnNames = keyof (typeof investigationSearchColumns)['column']

interface Props {
  draggableColumns: Array<ColumnProps>
  setDisplayColumns: (columns: Array<ColumnProps>) => void
  hideColumn: (columnName: ColumnProps) => void
  loading: boolean
}

const DynamicItems: FC<Props> = ({
  draggableColumns,
  setDisplayColumns,
  hideColumn,
  loading,
}) => {
  const { t } = useTranslation()

  const visibleColumns = draggableColumns.filter((column) => !column.hidden)

  const handleOrdering = (position: number, ordering: ORDER): void => {
    const addPosition = ordering === ORDER.UP ? position - 1 : position + 1

    if (position === -1) {
      return
    }

    const movedColumn = draggableColumns[position]

    const removedColumns = draggableColumns.toSpliced(position, 1)
    const updated = removedColumns.toSpliced(addPosition, 0, movedColumn)

    setDisplayColumns(updated)
  }

  const renderTitle = (title: string): ReactElement => {
    const shouldTruncate = title.length > TRUNCATE_LENGTH

    return shouldTruncate ? (
      <Tooltip
        title={<Text className={styles.tooltipText}>{title}</Text>}
        placement="right"
      >
        <Text block className={styles.itemText}>
          {title}
        </Text>
      </Tooltip>
    ) : (
      <Text>{title}</Text>
    )
  }

  return (
    <SortableContext items={draggableColumns} strategy={rectSortingStrategy}>
      <Flex gap={8} vertical data-test-id="visibleColumns">
        {visibleColumns.map((column, position) => {
          const { name, id } = column
          const title = t(`investigationSearch.column.${name as ColumnNames}`)

          if (loading) {
            return <Skeleton.Input key={name} size="large" block active />
          }

          return (
            <Droppable id={name} key={name}>
              <Draggable id={id}>
                <Flex align="center" gap={8} className={styles.iconContainer}>
                  <Icon className={styles.icon} component={DragIcon} />
                  <Flex align="center" gap={4}>
                    <Button
                      type="primary"
                      data-test-id={`orderDownIcon-${name}`}
                      data-analytics-id={`order-Down-${name}`}
                      disabled={position === visibleColumns.length - 1}
                      size="small"
                      className={styles.orderButton}
                      onClick={() => handleOrdering(position, ORDER.DOWN)}
                    >
                      <DownOutlined className={styles.orderButtonIcon} />
                    </Button>
                    <Button
                      data-test-id={`orderUpIcon-${name}`}
                      data-analytics-id={`order-up-${name}`}
                      disabled={position === 0}
                      type="primary"
                      size="small"
                      className={styles.orderButton}
                      onClick={() => handleOrdering(position, ORDER.UP)}
                    >
                      <UpOutlined className={styles.orderButtonIcon} />
                    </Button>
                  </Flex>
                  {renderTitle(title)}
                </Flex>

                <Button
                  type="link"
                  data-analytics-id={`hide-drawer-button-${name}`}
                  data-test-id={`hideDrawerButton-${name}`}
                  onClick={() => hideColumn(column)}
                >
                  {t(`columnDrawer.hideColumn`)}
                </Button>
              </Draggable>
            </Droppable>
          )
        })}
      </Flex>
    </SortableContext>
  )
}

export default DynamicItems
