import { FC, useEffect, useState } from 'react'
import { InvestigationInfo } from '@signifyd/http'
import { useLocation } from 'react-router'
import { Key, TableRowSelection } from 'antd/es/table/interface'
import { Spinner, useIsThirdGen } from '@signifyd/components'
import { BulkActionsProvider } from 'pages/InvestigationSearchPage/components/BulkActions/BulkActionsProvider'
import useGetUserBulkActionsPermission from 'core/hooks/useGetUserBulkActionsPermission'
import { useTableContext } from '../SearchContainer/TableProvider'
import { BulkActions } from '../../components/BulkActions'
import useGetInvestigations from './useGetInvestigations'
import SearchResultsTable from './SearchResultsTable'
import ThirdGenSearchResultsTable from './ThirdGenSearchResultsTable'

const TableContainer: FC = () => {
  const { search } = useLocation()
  const [selectedInvestigations, setSelectedInvestigations] = useState<
    Array<InvestigationInfo>
  >([])

  const { data, isLoading } = useGetInvestigations()
  const { data: canPerformBulkAction } = useGetUserBulkActionsPermission()

  const isThirdGen = useIsThirdGen()

  const { scrollToSavedPosition, selectedRowKeys, setSelectedRowKeys } =
    useTableContext()

  const handleSelectChange = (
    selectedRowKeys: Array<Key>,
    selectedRows: Array<InvestigationInfo>
  ): void => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedInvestigations(selectedRows)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
    getCheckboxProps: (record: { investigationId: number }) => ({
      'data-test-id': `bulkActionsCheckbox-${record.investigationId}`,
      'data-analytics-id': 'bulk-actions-checkbox',
    }),
  } as TableRowSelection<InvestigationInfo>

  useEffect(() => {
    setSelectedRowKeys([])
    setSelectedInvestigations([])
  }, [search, setSelectedRowKeys])

  useEffect(() => {
    if (!isLoading) {
      scrollToSavedPosition()
    }
  }, [isLoading, scrollToSavedPosition])

  if (!data) {
    return <Spinner />
  }

  const bulkActionChange = canPerformBulkAction ? rowSelection : undefined

  const ResultsTable = isThirdGen
    ? ThirdGenSearchResultsTable
    : SearchResultsTable

  return (
    <BulkActionsProvider
      onSelectChange={handleSelectChange}
      selectedInvestigations={selectedInvestigations}
      allInvestigations={data?.investigations ?? []}
    >
      <BulkActions />
      <ResultsTable bulkActionChange={bulkActionChange} />
    </BulkActionsProvider>
  )
}

export default TableContainer
