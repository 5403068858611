import { Button, Drawer, Flex, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  Active,
  DndContext,
  Over,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { arrayMove } from '@dnd-kit/sortable'
import { TableColumnIcon, Text, Space, T4 } from '@signifyd/components'
import { FC, useState } from 'react'
import Icon, { CloseOutlined } from '@ant-design/icons'
import { isEqual } from 'lodash'
import { useQueryClient } from '@tanstack/react-query'
import useGetUserData from 'core/hooks/useGetUserData'
import ColumnDrawerFooter from './ColumnDrawerFooter'
import LockedItems from './components/LockedItems/LockedItems'
import HiddenColumnsEmpty from './components/HiddenColumnsEmpty/HiddenColumnsEmpty'
import DynamicItems from './components/DynamicItems/DynamicItems'
import { Columns } from './useGetSavedColumns'
import HiddenItems from './components/HiddenItems/HiddenItems'
import styles from './ColumnDrawer.less'
import useUpdateUiState from '../NavigationWrapper/useUpdateUiState'
import { ColumnContextMapKeys } from '../../containers/SearchResultsTable/ColumnConfig.utils'

const SENSOR_DISTANCE = 4

export interface ColumnProps {
  id: ColumnContextMapKeys
  name: string
  hidden: boolean
  locked?: boolean
}

interface DragProps {
  active: Active
  over: Over | null
}

const ColumnDrawer: FC<{ data: Columns }> = ({ data }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { currentUser } = useGetUserData()
  const [open, setOpen] = useState(false)
  const [popconfirmVisible, setPopconfirmVisible] = useState(
    data.similarityColumnHidden
  )
  const [displayColumns, setDisplayColumns] = useState<Array<ColumnProps>>(
    data.columns
  )

  const hiddenColumns = displayColumns.filter((column) => column.hidden)
  const draggableColumns = displayColumns.filter((column) => !column.locked)
  const lockedColumns = data.columns.filter((column) => column.locked)

  // Sensors are required to handle the up/down on click listeners, without this, they do not work.
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: SENSOR_DISTANCE,
      },
    })
  )

  const updateUiState = useUpdateUiState(currentUser)

  const hasChanges = !isEqual(data?.columns, displayColumns)

  const handleToggle = (): void => {
    setOpen(!open)
    setDisplayColumns(data.columns)
  }

  const handleOnCancel = (): void => {
    if (!hasChanges) {
      handleToggle()
    }
  }

  const handleApplyChanges = ({
    resetToDefault = false,
  }: {
    resetToDefault?: boolean
  } = {}): void => {
    const hiddenColumnNames = hiddenColumns.map((column) => column.id)
    const displayColumnNames = displayColumns.map((column) => column.id)
    const lockedColumnNames = lockedColumns.map((column) => column.id)

    updateUiState.mutate(
      {
        powersearch: resetToDefault
          ? {}
          : {
              hiddenColumns: hiddenColumnNames,
              columnOrdering: Array.from(
                new Set(lockedColumnNames.concat(displayColumnNames))
              ),
            },
      },
      {
        onSettled: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['searchContext'],
          })
          setOpen(!open)
        },
      }
    )
  }

  const resetToDefault = (): void => {
    handleApplyChanges({ resetToDefault: true })
  }

  const hideColumn = (column: ColumnProps): void => {
    const { id } = column

    const columnPosition = displayColumns.findIndex(
      (column) => column.id === id
    )

    const newColumns = displayColumns.with(columnPosition, {
      ...column,
      hidden: true,
    })

    setDisplayColumns(newColumns)
  }

  const showColumn = (column: ColumnProps): void => {
    const { id } = column

    const columnPosition = displayColumns.findIndex(
      (column) => column.id === id
    )

    const removedColumns = displayColumns.toSpliced(columnPosition, 1)

    const newColumns = [...removedColumns, { ...column, hidden: false }]

    setDisplayColumns(newColumns)
  }

  const handleDrag = ({ active, over }: DragProps): void => {
    if (over && active.id !== over?.id) {
      const activeIndex = displayColumns.findIndex(({ id }) => id === active.id)
      const overIndex = displayColumns.findIndex(({ id }) => id === over.id)

      setDisplayColumns(arrayMove(displayColumns, activeIndex, overIndex))
    }
  }

  const drawerButtonText = hiddenColumns.length
    ? t('columnDrawer.hiddenColumnsButton', { count: hiddenColumns.length })
    : t('columnDrawer.button')

  return (
    <>
      <Popconfirm
        overlayClassName="sig-popconfirm"
        icon={null}
        open={popconfirmVisible}
        title={
          <div data-test-id="hiddenColumnsPopoverTitle">
            <T4>{t('columnDrawer.hiddenColumnsPopconfirm.title')}</T4>
            <Text className={styles.similarityPopoverText} type="secondary">
              {t('columnDrawer.hiddenColumnsPopconfirm.subtext')}
            </Text>
          </div>
        }
        onConfirm={() => {
          setOpen(true)
          setDisplayColumns(data.columns)
          setPopconfirmVisible(false)
        }}
        onCancel={() => {
          setOpen(false)
          setPopconfirmVisible(false)
        }}
        okText={t('columnDrawer.seeColumns')}
        cancelText={t('columnDrawer.skip')}
      >
        <Button
          type="link"
          data-analytics-id="column-drawer-button"
          data-test-id="columnDrawerButton"
          onClick={handleToggle}
        >
          <Icon component={TableColumnIcon} />
          {drawerButtonText}
        </Button>
      </Popconfirm>

      <Drawer
        onClose={handleOnCancel}
        data-test-id="columnDrawer"
        open={open}
        width={520}
        closable={false}
        footer={
          <ColumnDrawerFooter
            loading={updateUiState.isLoading}
            onCancel={handleOnCancel}
            onApply={handleApplyChanges}
            onClear={handleToggle}
            resetToDefault={resetToDefault}
            hasChanges={hasChanges}
          />
        }
        title={
          <Flex justify="space-between">
            <Text className={styles.title} weight="semibold">
              {t('columnDrawer.title')}
            </Text>
            <Popconfirm
              overlayClassName="sig-popconfirm"
              data-test-id="drawerCloseConfirmTop"
              icon={null}
              title={
                <>
                  <T4>{t('columnDrawer.confirmChanges.title')}</T4>
                  <Text type="secondary">
                    {t('columnDrawer.confirmChanges.subtext')}
                  </Text>
                </>
              }
              onConfirm={handleToggle}
              disabled={!hasChanges || updateUiState.isLoading}
              placement="topRight"
              okText={t('columnDrawer.confirmChanges.cancel')}
              cancelText={t('columnDrawer.confirmChanges.keep')}
            >
              <CloseOutlined onClick={handleOnCancel} />
            </Popconfirm>
          </Flex>
        }
      >
        <Text size="lg" weight="semibold">
          {t('columnDrawer.visibleColumns')}
        </Text>
        <Space size="sm" />
        <LockedItems lockedColumns={lockedColumns} />
        <Space size="xs" />
        <DndContext
          sensors={sensors}
          modifiers={[restrictToFirstScrollableAncestor]}
          onDragEnd={({ active, over }) => handleDrag({ active, over })}
        >
          <DynamicItems
            loading={updateUiState.isLoading}
            draggableColumns={draggableColumns}
            setDisplayColumns={setDisplayColumns}
            hideColumn={hideColumn}
          />
        </DndContext>
        <Space size="sm" />
        <Text size="lg" weight="semibold">
          {t('columnDrawer.hiddenColumns')}
        </Text>
        <Space size="md" />

        {hiddenColumns.length > 0 && (
          <HiddenItems
            loading={updateUiState.isLoading}
            displayColumns={displayColumns}
            showColumn={showColumn}
          />
        )}

        {!hiddenColumns.length && <HiddenColumnsEmpty />}
      </Drawer>
    </>
  )
}

export default ColumnDrawer
